<template>
	<div class="footer-information">
		<div v-if="!isBroker" class="footer-information__left">
			<div class="footer-information__logo">
				<img
					src="@/assets/img/svg/logo.svg"
					width="232"
					height="52"
					alt="logo"
					decoding="async"
				/>
			</div>
			<div class="footer-information__policy">
				<p>2023 OWREALTY, Inc. All rights reserved</p>
				·
				<router-link
					:to="{
						name: 'Privacy',
						params: { lang: $store.state.uiLanguage }
					}"
				>
					{{ $t('general.footerPrivacy') }}
				</router-link>
				·
				<router-link
					:to="{ name: 'Terms', params: { lang: $store.state.uiLanguage } }"
				>
					{{ $t('general.footerTerms') }}
				</router-link>
				·
				<router-link
					:to="{ name: 'Cookies', params: { lang: $store.state.uiLanguage } }"
				>
					{{ $t('general.footerCookies') }}
				</router-link>
			</div>
			<div class="footer-information__address">
				The Baygate Tower, 18 fl. 05 office RERA: 1125655 ORN: 33004
			</div>
			<div class="footer-information__info">
				<a href="tel:+971509156544">+971 50 915 6544</a>
				<a href="mailto:info@owrealty.ae">info@owrealty.ae</a>
			</div>
		</div>
		<div class="footer-information__right">
			<!-- <a
				href="https://t.me/seven_gates_ai_bot?start=7G-KUQ3DW"
				target="_blank"
				class="footer-btn footer-information__gate"
			>
				<img
					src="@/assets/img/pages/Gates-bg.webp"
					alt="7Gates"
					decoding="async"
				/>
				<span>{{ $t('other.aboutGates') }}</span>
			</a> -->

			<div class="footer-information__apps footer-apps">
				<router-link
					v-if="isShowBrokerAccountButton"
					:to="{
						name: 'Login',
						params: { lang: $store.state.uiLanguage }
					}"
					class="footer-btn footer-information__broker-link footer-apps__link"
				>
					{{ $t('web.broker_account') }}
				</router-link>
				<a
					class="footer-btn footer-apps__link"
					v-for="app in apps"
					:key="app.title"
					target="_blank"
					:href="app.link"
				>
					<img
						:src="require(`@/assets/img/svg/${app.image}.svg`)"
						:alt="app.title"
						width="85"
						height="24"
					/>
				</a>
			</div>
			<div class="footer-information__socials-wrapper">
				<div>
					<img
						src="@/assets/img/svg/payment.svg"
						alt=""
						decoding="async"
						class="footer-information__socials-img"
					/>
				</div>
				<ul class="footer-information__socials footer-social">
					<li v-for="item in socials" :key="item.title">
						<a
							class="footer-btn footer-social__link"
							:href="item.link"
							target="_blank"
						>
							<img
								:src="require(`@/assets/img/svg/socials/${item.image}.svg`)"
								:alt="item.title"
								decoding="async"
								:width="item.imageSize.width"
								:height="item.imageSize.height"
							/>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isBroker: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		isShowBrokerAccountButton() {
			return !['BrokerOffer'].includes(this.$route.name)
		},
		apps() {
			return [
				{
					title: 'Google play app',
					image: 'GooglePlay',
					link: 'https://play.google.com/store/apps/details?id=ae.owrealty'
				},
				{
					title: 'AppStore app',
					image: 'AppStore',
					link: 'https://apps.apple.com/us/app/owrealty-uae-real-estate/id6449245572'
				}
			]
		},
		socials() {
			return [
				{
					title: 'Twitter',
					link: 'https://twitter.com/Owrealty_Dubai',
					image: 'twitter',
					imageSize: { width: '24', height: '24' }
				},
				{
					title: 'Facebook',
					link: 'https://www.facebook.com/profile.php?id=100088554892839',
					image: 'fb',
					imageSize: { width: '9', height: '18' }
				},
				{
					title: 'Instagram',
					link: 'https://www.instagram.com/owrealty_uae',
					image: 'instagram',
					imageSize: { width: '24', height: '24' }
				}
			]
		}
	}
}
</script>

<style lang="scss">
.footer-information {
	display: flex;
	gap: 40px;
	justify-content: space-between;

	&__left {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
	}
	&__logo {
		margin-bottom: 15px;

		img {
			width: 232px;
			height: 52px;
		}
	}
	&__policy {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;
		margin-bottom: 22px;
		color: #404040;

		a {
			color: #404040;

			&:hover {
				text-decoration: underline;
			}
		}

		p {
			margin: 0;
		}
	}
	&__address {
		margin-bottom: 5px;
		color: #404040;
	}
	&__info {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 20px;
		row-gap: 5px;

		a {
			color: #808080;

			&:hover {
				color: #c99d56;
			}
		}
	}
	&__right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-end;
		margin-left: auto;
	}

	// &__gate {
	// 	max-width: 170px;
	// 	width: 100%;
	// 	position: relative;

	// 	img {
	// 		position: absolute;
	// 		width: 100%;
	// 		height: 100%;
	// 		left: 0;
	// 		top: 0;
	// 		object-fit: scale-down;
	// 	}

	// 	span {
	// 		position: absolute;
	// 		bottom: -24px;
	// 		color: #4d4d4d;
	// 		font-size: 12px;
	// 		line-height: 16px;
	// 		white-space: nowrap;
	// 	}
	// }

	&__socials {
		display: flex;
		align-items: center;
		gap: 15px;
		&-img {
			height: 30px;
			aspect-ratio: 140 / 30;
			object-fit: contain;
		}
		&-wrapper {
			display: flex;
			align-items: center;
			gap: 40px;
		}
	}

	&__broker-link {
		color: #c99d56;
		white-space: nowrap;
		font-size: 14px;
		font-weight: 600;
		line-height: normal;
	}

	.footer-social {
		&__link {
			width: 44px;
		}
	}

	.footer-apps {
		display: flex;
		gap: 20px;
		&__link {
			width: 100%;
			padding: 0 20px;
		}
	}
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		&__left {
			order: 1;
		}
	}
	@media screen and (max-width: 913px) {
		row-gap: 64px;
		&__socials {
			gap: 10px;
		}
		&__right {
			align-self: center;
			width: 100%;
		}
		&__gate {
			span {
				left: 0;
			}
		}
		&__logo {
			margin-bottom: 22px;

			img {
				width: 140px;
				height: 32px;
			}
		}

		&__policy {
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 30px;
		}

		&__address {
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 10px;
		}
		&__broker-link {
			order: 1;
		}
		.footer-apps {
			gap: 10px;
			flex-wrap: wrap;
			width: 100%;
			& > a {
				flex: 1 1 150px;
			}
		}
	}
	@media screen and (max-width: 479px) {
		&__socials {
			&-img {
				height: 25px;
			}
			&-wrapper {
				width: 100%;
				gap: 10px;
				justify-content: space-between;
			}
		}
	}
	.footer-btn {
		height: 44px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}
</style>
